.mermaid {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 2%;
}

.mermaid > svg {
    margin: auto;
    display: block;
    height: auto;
    padding-bottom: 2em;
    padding-top: 2em;
    max-width: 100%;
}
